<template>
  <div>
    <page-header :tabsItems="tabsItems" :title="title"></page-header>
    <v-card flat>
      <v-toolbar flat></v-toolbar>
    </v-card>
    <v-container fluid v-if="!loaded">
      <v-row justify="center">
        <v-col sm="12" md="10" lg="10">
          <v-skeleton-loader type="card-heading"></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" sm="12" md="4" lg="4">
          <v-skeleton-loader type="list-item-avatar-three-line"></v-skeleton-loader>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid v-else-if="loaded && students.length <= 0">
      <v-row justify="center">
        <v-col sm="12" md="10" lg="10">
          <!-- <v-card flat>
            <v-toolbar flat></v-toolbar>
          </v-card> -->
          <v-card outlined tile
            class="text-center py-10"
            >
            <v-sheet class="pb-10 text-h6">등록된 학생이 없습니다</v-sheet>
            <v-sheet>
              <v-btn
                color="primary"
                outlined tile
                @click="goStd">학생 등록하러 가기</v-btn>
            </v-sheet>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid v-else>
      <v-row justify="center">
        <v-col sm="12" md="10" lg="10">
          <v-sheet
            max-width="sliderWidth"
          >
            <v-slide-group show-arrows>
              <v-slide-item
                v-for="item in students"
                :key="item.std.id"
              >
                <v-btn
                  class="mr-2"
                  :input-value="item.active"
                  active-class="purple white--text"
                  depressed
                  tile
                  @click="toggle(item)"
                >
                  {{ item.std.name }}
                </v-btn>
              </v-slide-item>
            </v-slide-group>
          </v-sheet>
        </v-col>
      </v-row>

      <v-row justify="center" v-if="student.id">
        <v-col cols="12" sm="12" md="10" lg="10">
          <v-card
            outlined tile
          >
            <v-list-item>
              <v-list-item-action class="ml-5">
                <v-avatar :size="photoSize" v-if="student.photoURL">
                  <img
                    :src="student.photoURL"
                  >
                </v-avatar>
                <v-avatar :size="photoSize" v-else>
                  <v-icon :size="photoSize" color="grey lighten-3">mdi-account-circle</v-icon>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content class="ml-10 my-1 my-md-5">
                <v-row>
                  <v-col cols="12" sm="6" lg="4" class="d-flex align-center">
                    <v-list-item-title>
                      <div class="text-h6 mb-2">
                        {{ student.name }}
                      </div>
                      <div class="text-subtitle-2" style="padding-left:2px">
                        {{ student.grade }}
                      </div>
                      <div class="text-body-1">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">

                              <v-icon
                                  dense
                                  color="red darken-2"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >mdi-stamper</v-icon>
                                {{ student.stickerCnt }}
                              </template>
                            <span>스티커</span>
                          </v-tooltip>
                          /
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              {{ student.pointScore }}
                              <v-icon
                                  dense
                                  color="teal darken-2"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >mdi-file-powerpoint-box</v-icon>
                            </template>
                            <span>포인트</span>
                          </v-tooltip>
                      </div>
                    </v-list-item-title>
                  </v-col>
                  <v-col cols="12" sm="6" lg="8" class="py-0 d-flex align-center">
                    <v-row>
                      <v-col cols="12" md="6" class="d-flex align-center">
                        <v-list-item-subtitle>
                        <div class="text-subtitle-1 font-weight-bold">{{ student.userid }}</div>
                        <div class="text-caption">{{ displayTime }}</div>
                        </v-list-item-subtitle>
                      </v-col>
                      <v-col cols="12" md="6">
                        <!-- <v-btn
                          outlined tile
                          v-bind="btnSize"
                          color="primary"
                        ><v-icon>mdi-card-text-outline</v-icon></v-btn> -->
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>

          </v-card>
        </v-col>
      </v-row>
      <v-row justify="center" class="mb-10" v-if="student.id">
        <v-col cols="12" sm="12" md="10" lg="10">
          <v-card
            class="d-flex flex-wrap pa-3"
            outlined tile
          >
            <v-card
              v-for="item in iconItems"
              :key="item.index"
              flat
              class="pa-3"
            >
              <v-btn
                fab outlined
                @click="clickIcon(item.index)"
                :class="{ activeIcon: item.activeIcon, nonactiveIcon: item.nonactiveIcon}"
              >
                <v-icon
                  :size="iconsize"
                >{{ student.stickerIcon }}</v-icon>
              </v-btn>
            </v-card>

          </v-card>
        </v-col>
      </v-row>

    </v-container>
  </div>

</template>
<script>
import PageHeader from '../PageHeader'

export default {
  components: { PageHeader },
  // props: ['student'],
  data () {
    return {
      title: '학원관리',
      tabsItems: [
        { id: 1, title: '학원정보', link: '/manage/academy' },
        { id: 2, title: '학생관리', link: '/manage/students' },
        { id: 3, title: '포인트', link: '/manage/point' },
        { id: 4, title: '즐겨찾기', link: '/manage/links' }
      ],
      stickerTot: 0,
      iconsize: 50,
      iconItems: [],
      // student: {
      //   id: '',
      //   name: '',
      //   grade: '',
      //   photoURL: '',
      //   photoName: '',
      //   stickerIcon: '',
      //   stickerCnt: '',
      //   pointScore: '',
      //   academy: '',
      //   status: '',
      //   createdAt: ''
      // },
      student: {},
      students: [],
      photoSize: 76,
      sliderWidth: 800,
      loaded: false
    }
  },
  created () {
    if (this.$route.params.student) {
      this.student = this.$route.params.student
    }

    this.stickerTot = localStorage.stickerTot

    // 학생 정보 get
    this.read()
  },
  mounted () {
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },
  beforeDestroy () {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true })
    }
  },
  computed: {
    btnSize () {
      const size = { xs: 'small', sm: 'small', md: '' }[this.$vuetify.breakpoint.name]
      return size ? { [size]: true } : {}
    },
    displayTime () {
      const bt = this.$moment(this.student.createdAt).format('YYYY-MM-DD')
      return bt
    }
  },
  methods: {
    onResize () {
      if (window.innerWidth < 600) {
        this.iconsize = 46
        // this.photoSize = 76
      } else {
        this.iconsize = 52
        // this.photoSize = 76
      }
      this.sliderWidth = window.innerWidth
    },
    read () {
      let academyid = ''
      try {
        academyid = this.$store.state.user.uid
      } catch (e) {
        academyid = localStorage.acid
      }

      try {
        this.loaded = false
        this.$firebase.firestore().collection('students')
          .where('academy', '==', academyid)
          .orderBy('name')
          .onSnapshot((sn) => {
            this.loaded = true

            if (sn.empty) {
              this.students = []
            }

            this.students = sn.docs.map(v => {
              const item = v.data()
              return {
                active: false,
                std: {
                  id: v.id,
                  name: item.name,
                  grade: item.grade,
                  photoURL: item.photoURL,
                  photoName: item.photoName,
                  stickerIcon: item.stickerIcon,
                  stickerCnt: Number(item.stickerCnt),
                  pointScore: Number(item.pointScore),
                  academy: item.academy,
                  status: item.status,
                  createdAt: item.createdAt.toDate(),
                  userid: item.userid
                }
              }
            })
            if (this.student.id) {
              const item = this.students.find(t => t.std.id === this.student.id)
              item.active = true
              this.initSticker()
            } else {
              if (this.students.length > 0) {
                this.student = this.students[0].std
                this.students[0].active = true
                this.initSticker()
              }
            }
          })
      } catch (e) {
        console.log('student get', e.message)
      }
    },
    toggle (item) {
      for (let i = 0; i < this.students.length; i++) {
        this.students[i].active = false

        if (this.students[i].std.id === item.std.id) {
          this.students[i].active = true
          this.student = item.std
          this.initSticker()
        }
      }
    },
    clickIcon (idx) {
      // 마지막 or 마지막 +1 스티커만 선택할 수 있다
      if (idx < this.student.stickerCnt || idx > this.student.stickerCnt + 1) return
      const item = this.iconItems.find(t => t.index === idx)
      item.activeIcon = !item.activeIcon
      item.nonactiveIcon = !item.nonactiveIcon

      if (item.activeIcon) {
        this.student.stickerCnt++
      } else {
        this.student.stickerCnt--
      }

      if (this.student.stickerCnt >= this.stickerTot) {
        // 스티커 = 0, 포인트 = +보상
        this.student.stickerCnt = 0
        const reward = localStorage.stickerReward
        this.student.pointScore = Number(this.student.pointScore) + Number(reward)

        try {
          this.$firebase.firestore().collection('students').doc(this.student.id).update({
            stickerCnt: Number(this.student.stickerCnt),
            pointScore: Number(this.student.pointScore)
          }).then(() => {
            // console.log('update complete')
          })
        } catch (e) {
          console.log(e)
        }
      } else {
        this.$firebase.firestore().collection('students').doc(this.student.id).update({
          stickerCnt: Number(this.student.stickerCnt)
        }).then(() => {
          // console.log('update complete', this.student.stickerCnt)
        })
      }
    },
    initSticker () {
      this.iconItems = []
      for (let i = 1; i <= this.stickerTot; i++) {
        let onOff = false
        let nonOnOff = true
        if (i <= this.student.stickerCnt) {
          onOff = true
          nonOnOff = false
        }
        const item = { index: i, activeIcon: onOff, nonactiveIcon: nonOnOff }
        this.iconItems.push(item)
      }
    },
    goStd () {
      this.$router.push('/manage/students')
    }
  }
}
</script>
<style scoped>
.activeIcon { color: orangered }
.nonactiveIcon { color: #BDBDBD }
</style>
