<template>
    <div>
        <!-- <v-toolbar
                dense
                tabs
                color="primary"
                dark
        >
          <v-toolbar-title class="pl-2">{{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>

          <v-tabs
            slot="extension"
            slider-color="white"
            dark
          >
            <v-tab
              v-for="tabsItem in tabsItems"
              :key="tabsItem.id"
              :to="tabsItem.link"
            >
              <span class="pl-2 pr-2">{{ tabsItem.title }}</span>
            </v-tab>
          </v-tabs>
        </v-toolbar> -->
        <v-tabs
          slot="extension"
          slider-color="white"
          dark
          background-color="primary"
        >
          <v-tab
            v-for="tabsItem in tabsItems"
            :key="tabsItem.id"
            :to="tabsItem.link"
          >
            <span class="pl-2 pr-2">{{ tabsItem.title }}</span>
          </v-tab>
        </v-tabs>

    </div>
</template>

<script>
export default {
  props: ['title', 'tabsItems'],
  data () {
    return {
      //
    }
  }
}
</script>

<style scoped>

</style>
